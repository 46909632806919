@charset "utf-8";
@import url('fonts/source-sans-pro/source-sans-pro.css');

// KBZ variables
$kbz-blauw :        rgb(0, 161, 206);
$kbz-donker-blauw : rgb(0, 0, 64);
$kbz-goud :         rgb(123, 113, 64);
$kbz-rood :         rgb(255, 0, 96);
$kbz-licht-rood :   rgba($kbz-rood, 0.3);

$kbz-grijs :        rgb(242, 242, 242);

$footer-height : 200px;

$bold-font-weight: 400;


// Override Bootstrap
$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-size-base-px: 14;
$font-size-base: 14px;
$text-color: $kbz-donker-blauw;
$headings-font-weight: $bold-font-weight;

$btn-default-color:              $kbz-donker-blauw;
$btn-default-bg:                 #fff;
$btn-default-border:             $kbz-donker-blauw;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $kbz-donker-blauw;
$btn-primary-border:             $kbz-donker-blauw;

$label-info-bg:               $kbz-donker-blauw;

$border-radius-base:        	15px;
$padding-base-vertical:     4px;
$padding-base-horizontal:   10px;

$input-border-radius:            0;
$input-border-radius-large:      0;
$input-border-radius-small:      0;

$alert-border-radius:         0px;
$alert-padding:               10px;

$state-success-text:             #fff;
$state-success-bg:               $kbz-blauw;
$state-success-border:           $kbz-blauw;

$state-info-text:                #fff;
$state-info-bg:                  $kbz-donker-blauw;
$state-info-border:              $kbz-donker-blauw;

$state-warning-text:             #fff;
$state-warning-bg:               $kbz-goud;
$state-warning-border:           $kbz-goud;

$state-danger-text:              #fff;
$state-danger-bg:                $kbz-rood;
$state-danger-border:            $kbz-rood;


.help-block {
	font-size: 12px;
	line-height: 12px;
}

/**
 * Bootstrap @media widths: (@see _variables.scss)
 * $screen-lg-min: 1200px
 * $screen-md-min:  992px < col-md collapse
 * $screen-sm-min:  768px < menu collapse, col-sm- collapse
 * $screen-xs-min:  480px
 */

//@import '../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap';
/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/print";
//@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../../private/vendors/twbs/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

/*.btn-default:hover, .btn-default:focus, .btn-default.focus {
	background-color: rgb(184, 184, 204);
	border-color: rgb(184, 184, 204);
}
*/
.btn-default:hover, .btn-default:focus, .btn-default.focus, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
	background-color: #BCBCBC;
	border-color: #BCBCBC;
}

.alert {
	padding: 5px 10px;
	text-align: center;

	&.alert-success {
		position: relative;

		&:before, &:after {
			background-image: url(/img/icons/checkmark.svg);
			content: '';
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-position: center;
			display: inline-block;
			background-size: contain;
			position: absolute;
		}

		&:before {
			left: 10px;
		}

		&:after {
			right: 10px;
		}
	}
}

// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * $font-size-base-px) * 1px;
	font-size: $sizeValue * 1rem;
}

@mixin triangle-drop-shadow($pos: top, $offset: 15px) {
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 14px;
		height: 14px;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		@if $pos == left {
			top: $offset;
			left: -6px;
			border-top-right-radius: 10px;
			box-shadow: -1px 1px 8px -3px black;
		} @else if $pos == top-right {
			top: -6px;
			right: $offset;
			border-bottom-right-radius: 10px;
			box-shadow: -1px -1px 8px -3px black;
		} @else if $pos == bottom-right {
			bottom: -6px;
			right: $offset;
			border-top-left-radius: 10px;
			box-shadow: 1px 1px 8px -3px black;
		} @else {
			// top
			top: -6px;
			left: $offset;
			border-bottom-right-radius: 10px;
			box-shadow: -1px -1px 8px -3px black;
		}
		background-color: white;
		//-webkit-transform: rotate(45deg);
		   //-moz-transform: rotate(45deg);
			//-ms-transform: rotate(45deg);
			 //-o-transform: rotate(45deg);
				transform: rotate(45deg);
	}
	&:after {
		content: '';
		position: absolute;
		display: block;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		@if $pos == left {
			width: 10px;
			height: 25px;
			top: $offset - 5;
			left: 0px;
		} @else if $pos == top-right {
			width: 25px;
			height: 10px;
			top: 0px;
			right: $offset - 5;
		} @else if $pos == bottom-right {
			width: 25px;
			height: 10px;
			bottom: 0px;
			right: $offset - 5;
		} @else {
			width: 25px;
			height: 10px;
			top: 0px;
			left: $offset - 5;
		}
		background-color: white;
	}
}

html, body {
	color: $kbz-donker-blauw;
}

html {
	font-size: ($font-size-base-px/16) * 100%;
	position: relative;
	min-height: 100%;
}

body { margin-bottom: $footer-height; }

.dl-inline {
	dt, dd {
		float: left;
		display: block;
	}
	dt { clear: both; }
	dd {
		&:before, &:after {
			display: inline;
			content: ' ';
		}
	}
}

.list-group-item {
	.dl-inline {
		dt { margin-right: 0.5em; }
		dd {
			margin-right: 0.3em;
			&:after { content: '; '; }
			&.last-item {
				margin-right: 0;
				&:after { content: ''; }
			}
		}
	}
}

.nav {
	a { color: $kbz-donker-blauw; }
	> li > a {
		&:hover { background-color: rgba($kbz-blauw, 0.8); }
		&:focus {
			background-color: transparent;
			outline: 0;
		}
	}
	.hover-dropdown {
		> a { z-index: 10; }
		> .nav {
			position: absolute;
			display: none;
			z-index: 100;
			top: 0;
			left: 50%;
			@media (min-width: $screen-sm-min) {
				left: auto;
			}
		}
		&:hover > .nav {
			display: block;
		}
	}
}

#arrow-after {
	content: '';
	display: inline-block;
	width: 9px;
	height: 11px;
	background-image: url(/img/small-arrow.svg);
	background-repeat: no-repeat;
	display: inline-block;
	margin-left: 0.2em;
}

#popup-base {
	position: absolute;
	padding: 10px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 10px -2px black;
	@include font-size(0.9);
	z-index: 10000;
	> a.close {
		position: absolute;
		display: block;
		width: 10px * 1.4;
		height: 10px * 1.4;
		top: 7px;
		right: 7px;
		&:before, &:after {
			position: absolute;
			top: 50%;
			content: '';
			width: 10px * 1.4;
			height: 1px;
			background-color: $kbz-donker-blauw;
			transform: rotate(45deg);
			transform-origin: 50% 50%;
		}
		&:after { transform: rotate(-45deg); }
	}
}

.popup {
	@extend #popup-base;
	display: none;
}

.list {
	> table > tbody > tr.unavailable { opacity: 0.5 }
	&.list-publications {
		padding: 0;
		> table {
			> thead {
				> tr {
					> th, > td {
						border-bottom: none;
						font-weight: normal;
						text-transform: uppercase;
					}
					> th > a {
						&.asc:after, &.desc:after { @extend #arrow-after; }
						&.desc:after { transform: rotate(180deg); }
					}
				}
			}
			> tbody {
				> tr {
					background-color: white;

					> th {
						width: 40%;
					}

					> th, > td {
						position: relative;
						border-top: none;
						font-weight: normal;
						> time { white-space: nowrap; }
						&.date { width: 11ex; }
						&.keywords { width: 20%; }
						.maybe-long {
							position: absolute;
							margin: 0;
							top: 8px;
							right: 8px;
							bottom: 8px;
							left: 8px;
							min-height: 20px;
							padding: 0;
							overflow: hidden;
							&.active:hover {
								// !!! must copy these values to javascript
								top: 0px;
								right: auto;
								bottom: auto;
								left: 0px;
								padding: 8px;
								background-color: white;
								//white-space: nowrap;
								z-index: 100;
								box-shadow: 0 0 0 8px rgba(black, 0.2);
							}
						}
						a { color: $kbz-donker-blauw; }
						&.icons {
							width: 30%;
							.icon {
								height: 14px;

								position: relative;
								.button {
									width: 20px;
									height: 20px;
									background-repeat: no-repeat;
									background-position: center center;
									cursor: pointer;
								}
								.popup {
									top: -10px;
									left: 120%;
									width: 195px;
									> header {
										display: block;
										height: 45px;
										margin-bottom: 10px;
										padding-bottom: 10px;
										border-bottom: 1px solid $kbz-grijs;
										.picture {
											display: inline-block;
											width: 35px;
											height: 35px;
											margin-right: 5px;
											background-color: darken($kbz-grijs, 10%);
											background-position: center center;
											background-repeat: no-repeat;
											background-size: cover;
											border-radius: 50%;
											vertical-align: middle;
										}
										.maintainer {
											display: inline-block;
											margin: 0;
											padding: 0;
											@include font-size(1);
											vertical-align: middle;
										}
									}
									@include triangle-drop-shadow(left);
									@media (max-width: $screen-md-min) { width: 155px; }
									@media (max-width: $screen-sm-min) {
										top: 30px;
										left: auto;
										right: -5px;
										@include triangle-drop-shadow(top-right);
									}
								}
								&.open .popup { display: block; }
							}
							.icon-comment .button {
								background-image: url(/img/comment.svg);
							}
						}
						&:hover { z-index: 100; }
					}
					&:nth-of-type(2n+1) {
						&, > td .maybe-long:hover { background-color: lighten($kbz-rood, 47%); }
					}
				}
			}
		}
	}
}

.map {
  position: relative;
	height: 600px;
}
.panel.map {
	border: none;
	padding: 0;
}
#map {
	width: 100%;
	height: 100%;
}
#map-infobox {
  position: absolute;
  > article {
    @extend #popup-base;
    position: relative;
    width: 300px;
    padding: 30px;
    border-radius: 30px;
    margin: 5px;
    &[data-href] { cursor: pointer; }
    .featured-image {
      max-width: 100%;
    }
    h1 {
      @include font-size(0.9);
      padding: 0;
      margin: 1em 0 0 0;
    }
    .publication-date { margin-top: 0; }
    @include triangle-drop-shadow(bottom-right, 30px);
    > a.close {
      top: 12px;
      right: 12px;
    }
  }
}

.nav-list-map-toggle {
	float: right;
	margin: 0;
	li {
		float: left;
		a {
			padding: 0.2em 1.5em;
			background-color: $kbz-grijs;
			color: $kbz-donker-blauw;
		}
		&.active a, a:hover {
			background-color: $kbz-rood;
		}
		&.active a { color: white; }
	}
}

.table th .comment { font-weight: normal; }

$menu-top-margin: 30px;
#header-map {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 165px;
	z-index: -1;
	background-color: rgba($kbz-blauw, 0.5);
	&:after {
		display: block;
		content: '';
		position: absolute;
		top: $menu-top-margin;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: white;
		opacity: 0.8;
	}
}
#header {
	position: relative;
	z-index: 200;
	padding: 0;
	height: 165px;
	h1 {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		a { text-decoration: none; }
	}
	#main-menu {
		position: relative;
		margin: $menu-top-margin 0 30px 0;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid;
		min-height: 0;
		padding: 0;
		.navbar-brand {
			padding: 0;
			margin-top: -1px;
			margin-right: 120px;
			height: 33px;
			line-height: 33px;
			color: $kbz-donker-blauw;
			&:hover { color: $kbz-blauw; }
		}
		.navbar-toggle {
			border: 1px solid rgba($kbz-donker-blauw, 0.8);
			.icon-bar { background-color: $kbz-donker-blauw; }
		}
		.nav {
			a[href='javascript:;'], a[href='#'] { cursor: default; }
			> .active {
				> a {
					background-color: rgba($kbz-blauw, 0.8);
					color: white;
				}
			}
			> li {
				height: auto;
				line-height: 32px;
				> a {
					position: relative;
					color: $kbz-donker-blauw;
					@include font-size(1.2);
					min-height: 32px;
					line-height: 20px;
					padding: 10px 30px 2px 10px;
					white-space: nowrap;
				}

				> .nav { width: 50%; }
				@media (min-width: $screen-sm-min) {
					> a { z-index: 100; }
					> .nav {
						z-index: 10;
						width: auto;
						min-width: 100%;
						top: -$menu-top-margin;
						padding-top: 20px;
					}
				}
			}
			.hover-dropdown {
				margin-top: -$menu-top-margin;
				padding-top: $menu-top-margin;
				height: $menu-top-margin + 32;
				> .nav {
					top: $menu-top-margin + 32;
					padding-bottom: 10px;
				}
				&.active, &:hover {
					> a {
						background-color: transparent;
						color: white;
						&:after {
							content: '';
							display: block;
							width: 9px;
							height: 11px;
							background-image: url(/img/small-arrow-white.svg);
							background-repeat: no-repeat;
							position: absolute;
							right: 29px;
							bottom: -13px;
						}
					}
				}
				> a:hover { color: white; }
				.nav > li {
					&.active > a {
						color: rgba(white, 0.9);
						background-color: transparent;
					}
					> a:hover {
						color: white;
						background-color: transparent;
					}
				}
				&.active, &:hover {
					.nav > li {
						display: none;
					}
				}
				&:hover {
					> a { background-color: transparent; }
					.nav li { display: block; }
				}
			}
			&.content-menu li a { text-transform: uppercase; }
			.collections {
				&.active, &.active-parent, &:hover, > .nav {
					background-color: rgba($kbz-rood, 0.8);
				}
			}
			.publications {
				&.active, &.active-parent, &.keep-open, &:hover, > .nav {
					background-color: rgba($kbz-goud, 0.8);
				}
				&.keep-open > .nav {
					&, & > li { display: block !important; }
				}
				> .nav {
					padding-bottom: 0;
					.form { height: 40px; }
					.navbar-form {
						padding: 0;
						margin: 0;
						> input {
							margin: 0;
							height: 40px;
							background-color: mix($kbz-goud, white, 75%);
							color: white;
							border-radius: 0;
							border-style: solid none;
							border-width: 2px 0;
							border-color: $kbz-goud;
							&::-webkit-input-placeholder { color: rgba(white, 0.5); }
									  &:-moz-placeholder { color: rgba(white, 0.5); } // Firefox 18-
									 &::-moz-placeholder { color: rgba(white, 0.5); } // Firefox 19+
								 &:-ms-input-placeholder { color: rgba(white, 0.5); }
						}
					}
				}
			}
			.profile {
				&.active, &.active-parent, &:hover, > .nav {
					background-color: rgba($kbz-blauw, 0.8);
				}
			}
		}
	}
}
.site-section {
	min-height: 100px;
	@include font-size(4);
	font-weight: $bold-font-weight;
	line-height: 100px;
	color: $kbz-rood;
	text-transform: uppercase;
	opacity: 0.3;
}
#container {
	position: relative;
	z-index: 150;
}
#content {
	position: relative;
	padding-bottom: 30px;
	z-index: 100;
}
#footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: $footer-height;
	margin: 0;
	padding: 0;
	background-color: $kbz-blauw;
	z-index: 100;
	a { padding: 0.5em; }
	.footer-brand {
		padding: 0.5em 0;
		> a { padding: 0; }
	}
	#footer-menu {
		padding: 0;
		> .nav > li {
			> a {
				display: inline-block;
				padding: 3px 0;
				text-align: left;
				border-bottom: 2px solid;
			}
			&.active > a {
				color: white;
				border-bottom-color: $kbz-donker-blauw;
			}
			> a:hover {
				color: white;
				background-color: transparent;
				border-bottom-color: white;
			}
		}
	}
}

body.c-users {
	&.a-login #content {
		.form-signin {
			max-width: 330px;
			padding: 15px;
			margin: 0 auto;
			.form-signin-heading, .checkbox { margin-bottom: 10px; }
			.checkbox { font-weight: normal; }
			.form-control {
				position: relative;
				height: auto;
				//-webkit-box-sizing: border-box;
				//-moz-box-sizing: border-box;
				box-sizing: border-box;
				padding: 10px;
				font-size: 16px;
			}
			.form-control:focus { z-index: 2; }
			input.form-control-username {
				margin-bottom: -1px;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
			input.form-control-password {
				margin-bottom: 10px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
}

body.c-pages {
	&.a-home #content {
		.recent-changes {
			.collections, .publications {
				.panel, .panel-heading, .list-group-item { border-radius: 0; }
				.panel {
					border: none;
					box-shadow: none;
				}
				.panel-heading {
					height: 40px;
					text-transform: uppercase;
					color: white;
					background-color: $kbz-blauw;
					> h3 {
						@include font-size(1.1);
						line-height: 20px;
					}
				}
				.list-group-item {
					border-top: none;
					border-bottom: 1px solid $kbz-blauw;
					margin-bottom: 0;
					p {
						@include font-size(0.9);
						color: $kbz-blauw;
						margin-top: 0;
					}
					h4 {
						text-transform: uppercase;
						@include font-size(1.2);
					}
				}
			}
			.collections {
				padding: 0 30px 0 0;
				.panel-heading { background-color: $kbz-rood; }
				.list-group-item {
					min-height: 100px;
					border-bottom-color: $kbz-rood;
					background-color: rgba($kbz-rood, 0.05);
					p { color: $kbz-rood; }
					&:hover { background-color: rgba($kbz-rood, 0.2); }
				}
			}
			.publications {
				padding: 0 0 0 30px;
				.panel-heading { background-color: $kbz-goud; }
				.list-group-item {
					border-bottom-color: $kbz-goud;
					background-color: rgba($kbz-goud, 0.05);
					p { color: $kbz-goud; }
					&:hover { background-color: rgba($kbz-goud, 0.2); }
				}
			}
		}
	}
	&.a-home.with-home-intro {
		#header-map { height: 165px + 275; }
		#container {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
			padding: 0;
		}
		#content {
			.home-intro {
				height: 275px;
				padding: 20px 0 50px 0;
				margin: 0 0 -40px 0;
				background-color: rgba(white, 0.5);
				border-bottom: 40px solid $kbz-donker-blauw;
				h1 {
					margin: 0 0 0.4em 0;
					@include font-size(3.4);
				}
				p {
					@include font-size(1.8);
					line-height: normal;
				}
			}
		}
	}
	&.a-view {
		#header-map { height: 165px + 35*2 + 20; }
		#container { padding: 0; }
		#content {
			> article {
				> header {
					padding: 0 16.66666667%;
					margin: 35px 0;
					border-bottom: 1px solid $kbz-donker-blauw;
					> h1 {
						height: 20px;
						line-height: 20px;
						padding: 0;
						margin: 0;
						@include font-size(1.2);
						text-transform: uppercase;
					}
				}
				> section {
					padding: 50px 16.66666667%;
					border-bottom: 1px solid $kbz-donker-blauw;
				}
			}
		}
	}
}

body.c-publications {
	&.a-index #content {
		.publications {
			> header { height: 50px; }
			> .map { display: none; }
		}
		.form {
			margin-top: 50px + 8 + 20 + 8; // th padding height padding
			padding-bottom: 50px;
			legend {
				position: relative;
				margin-bottom: 0;
				margin-top: 10px;
				@include font-size(1.3);
				span {
					position: absolute;
					right: 0;
					bottom: 0;
					@include font-size(0.8);
					cursor: pointer;
				}
			}
			.form-group {
				width: 100%;
				legend span { display: none; }
				label {
					position: relative;
					margin-left: 1.4em;
					input {
						position: absolute;
						left: -1.4em;
					}
				}
				&.has-checked {
					legend span { display: block; }
					.checkbox { display: none; }
					.checked { display: block; }
				}
			}
			.submit { margin-top: 10px; }
		}
	}
	&.a-view #content {
		.publications-collections {
			padding-top: 5px;

			a {
				color: #666;
			}
		}

		> article {
			margin-top: 190px;

			h1 {
				margin-top: 0;
				@include font-size(1.4);

				.icon {
					height: 14px;
				}
			}
			div.featured-image {
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
			@media (max-width: $screen-sm-min) {
				div.featured-image { margin-bottom: 20px; }
				.pull-right {
					clear: both;
					float: none !important;
				}
			}
			.meta {
				dt {
					color: $kbz-goud;
					font-weight: normal;
				}
				dd { display: inline; }
				dd + dd:before { content: '; '; }
				dd + dt { margin-top: 0.8em; }

				.more-meta {
					display: none;
					&.js-hide { display: block; }
				}
				.toggle-more-meta {
					display: block;
					cursor: pointer;
					span:after { @extend #arrow-after; }
					span.open {
						display: none;
						&:after { transform: rotate(180deg); }
					}
					&.show {
						span.closed { display: none; }
						span.open { display: inline; }
					}
					&.js-show { display: none; }
				}
				@media (max-width: $screen-sm-min) {
					dl {
						width: 50%;
						float: left;
						padding-right: 0.5em;
						&.more-meta {
							display: block !important;
							float: right;
							padding-right: 0;
							padding-left: 0.5em;
						}
					}
					.toggle-more-meta { display: none; }
				}
			}
		}
		.collections {
			ul {
				display: none;
			}

			button {
				background-color: $kbz-rood;
				border: 0;
				color: #fff;
				padding-right: 30px;
				position: relative;

				&:focus {
					outline: none;
				}

				&:after {
					content: '';
			    position: absolute;
			    top: 2px;
			    right: 6px;
			    width: 0;
			    height: 0;
			    border-style: solid;
			    border-color: transparent transparent transparent white;
			    border-width: 10px 0 10px 18px;
			    -webkit-transform: scale(0.5);
			    transform: scale(0.5);
				}

				&.active {
					border-color: white transparent transparent transparent;
					border-width: 18px 10px 10px 10px;
				}
			}

			ul {
				background-color: $kbz-licht-rood;
				margin: 5px 0;
				padding: 60px 165px;
				list-style-type: none;

				li {
					margin-bottom: 5px;

					a {
						border: 1px solid $kbz-rood;
						background-color: $kbz-rood;
						padding: 5px 10px;
						color: #fff;
						border-radius: 10px;
						display: block;
						text-decoration: none;

						&:hover, &:active {
							border: 1px solid $kbz-rood;
							color: $kbz-rood;
							background-color: transparent;
						}

						&.active {
							position: relative;

							&:after {
								background-image: url(/img/icons/checkmark.svg);
								content: '';
								width: 20px;
								height: 20px;
								background-repeat: no-repeat;
								background-position: center;
								display: inline-block;
								background-size: contain;
								position: absolute;
								right: 5px;
							}
						}

						&.add-collection {
							color: $kbz-blauw;
							background-color: #fff;

							&:hover, &:active {
								background: $kbz-blauw;
								color: #fff;
							}
						}
					}
				}
			}
		}
		.downloads {
			margin-top: 50px;
			margin-bottom: 25px;
			th {
				position: relative;
				cursor: default;
				padding-left: 0;
				@include font-size(1.2);
				.popup {
					top: 100%;
					min-width: 100px;
					font-weight: normal;
					@include triangle-drop-shadow();
				}
				&:hover .popup { display: block; }
			}
			td {
				position: relative;
				width: 10em;
				padding-left: 0;
				.download {
					position: absolute;
					min-width: 100%;
					cursor: default;
					padding: 2px 10px;
					background: $kbz-donker-blauw;
					color: white;
					border-radius: 10px;
					ul {
						display: none;
						margin: 0.6em 0 5px 0;
						padding: 0;
						list-style: none;
					}
					li {
						white-space: nowrap;
						padding-right: 5px;
						&:before {
							content: '\2022  '; // &bull;
							//content: '\00B7  '; // &middot;
							//font-weight: $bold-font-weight;
						}
					}
					a { color: white; }
					&:after {
						content: '';
						position: absolute;
						top: 2px;
						right: 6px;
						width: 0;
						height: 0;
						border-style: solid;
						border-color: transparent transparent transparent white;
						border-width: 10px 0 10px 18px;
						//-webkit-transform: scale(0.5);
						   //-moz-transform: scale(0.5);
						    //-ms-transform: scale(0.5);
						     //-o-transform: scale(0.5);
						        transform: scale(0.5);
					}
					&:hover {
						box-shadow: 0 0 8px -3px black, 0 0 8px -2px white;
						z-index: 10000;
						ul { display: block; }
						&:after { display: none; }
					}
				}
				@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) { width: 7em; }
			}
		}

		.notice {
			@include make-sm-column(12);

			p {
				border-top: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
				color: #666;
				padding: 20px 0;
			}
		}

		.access-form {
			@include make-sm-column(12);
		}
	}
}
body.c-collections {
	&.a-index #content {
		a.thumbnail{
			padding-left: 15px;
			padding-right: 15px;
			&:hover { text-decoration: none; }
		}
	}
	&.a-view #content {
		> article {
			> header {
				margin: 0;
				padding: 70px 0 50px 0;
				h1 {
					margin: 0;
					padding: 0;
					color: $kbz-rood;
					text-transform: uppercase;
					@include font-size(2.6);
				}
				.modification-date { margin: 0; }
			}
			.about-collection {
				background-color: $kbz-grijs;
				height: 370px;
				padding: 20px;
				> div {
					height: 100%;
					&:first-child { padding-left: 0; }
					&:last-child {
						//padding-right: 0; // padding for scroll-bar
						overflow-x: hide;
						overflow-y: scroll;
					}
				}
				.about-maintainer {
					> h2 {
						margin: 20px 0;
						@include font-size(1.2);
						font-weight: normal;
						text-transform: uppercase;
					}
					p { margin-top: 0; }
					> div {
						position: relative;
						display: table-cell;
						width: 37%;
						padding: 0 15px 15px 15px;
						margin: 0;
						@include font-size(0.9);
						vertical-align: top;
						border-right: 1px solid darken($kbz-grijs, 10%);
						&:first-of-type {
							width: 26%;
							padding-left: 0;
						}
					}
					.picture {
						width: 100%;
						height: 0;
						margin: 0;
						padding: 0 0 100% 0;
						background-color: darken($kbz-grijs, 10%);
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						border-radius: 50%;
					}
					@media (max-width: $screen-xs-min) {
						> div {
							width: 50%;
							&:first-of-type {
								width: 0;
								padding: 0;
							}
						}
						.picture { display: none; }
					}
				}
				.featured-image {
					margin: -20px 0 0 -20px;
					height: 0;
					padding-bottom: 40%;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
				}
				@media (max-width: $screen-md-min) {
					& { height: auto; }
					> div {
						padding: 0;
						+ div { margin-top: 30px; }
					}
					.featured-image { margin-right: -20px; }
				}
			}
			.publications {
				margin-top: 50px;
				padding: 0;
				> header {
					border-top: 1px solid $kbz-grijs;
					padding-right: 16.66666667%;
					height: 50px;
				}
				> .map { display: none; }
			}
		}
	}
}

#publication-form, #publication-access-form, #collection-form {
	fieldset {
		@include make-row();

		legend {
			@include make-sm-column(2);
		}

		.form-controls {
			@include make-sm-column(10);

			.help-block {
				@include make-sm-column-offset(2);
				@include make-sm-column(10);
			}
		}
	}
}

#publication-form, #publication-access-form, #collection-form, #register-form {
	padding: 50px 0;

	.form-header {
		@include make-row();
		margin-bottom: 100px;

		.form-header-inner {
			@include clearfix;
			margin: 0 15px;
			border-bottom: 1px solid $kbz-donker-blauw;
			padding-bottom: 5px;

			h1 {
				@include make-sm-column-offset(2);
				@include make-sm-column(5);
				text-transform: uppercase;
				margin: 0;
				padding-left: 0;
			}

			.form-toolbar {
				@include make-sm-column(5);
				text-align: right;
				padding-right: 0;

				.btn {
					margin: 5px;
				}
			}
		}

		h2 {
			@include make-sm-column-offset(2);
			@include make-sm-column(5);
			font-size: 21px;
			letter-spacing: 1px;
			margin: 0;
		}
	}

	.form-footer {
		@include clearfix;
		border-bottom: 1px solid $kbz-donker-blauw;
		padding: 15px 0;

		.form-toolbar {
			float: right;
		}
	}

	.form-header, .form-footer {
		.form-toolbar {
			text-transform: lowercase;

			label {
				font-weight: normal;
			}

			.btn {
				text-transform: lowercase;
			}

			.btn, label {
				margin: 0 5px;
			}
		}
	}

	fieldset {
		padding-top: 25px;
		padding-bottom: 25px;
		border-top: 1px solid $kbz-grijs;
		border-bottom: 1px solid $kbz-grijs;

		legend {
			font-size: $font-size-base;
			color: $kbz-donker-blauw;
			border: 0;
			text-transform: lowercase;

			.legend-notice {
				margin-top: 15px;
				display: block;
				color: #707070;
			}
		}

		.form-controls {
			.form-group {
				&.error {
					label {
						color: $kbz-rood;
					}

					.form-control {
						border-color: $kbz-rood;
					}
				}
			}

			.help-block {
				font-size: 12px;
				line-height: 12px;
				color: $kbz-rood;
			}

			.form-control {
				box-shadow: none;

				&:focus {
					border-color: $kbz-donker-blauw;
				}
			}

			&.quill {
				background-color: $kbz-grijs;
				padding: 25px;

				.toolbar, .editor {
					background-color: #fff;
					font-family: $font-family-sans-serif;
				}

				.editor {
					min-height: 130px;
				}

				.quill-counter {
					margin-top: 5px;
					text-align: right;

					&.exceeded-limit {
						color: $kbz-rood;
					}

					&.near-limit {
						color: $kbz-goud;
					}
				}
			}

			#location-map-container {
				position: relative;

				#location-map-overlay {
					background-color: $kbz-donker-blauw;
					opacity: 0.5;
					width: 100%;
					height: 100%;
					z-index: 1;
					position: absolute;
				}

				#location-map {
					width: 100%;
					height: 400px;
				}
			}
		}

		#location-map-edit {
			margin: 20px 0;

			#activate-location-edit {
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 15px;
				text-transform: lowercase;
			}

			.location-coordinate {
				margin: 0 15px;
			}

			#location-map-edit-search {
				margin-top: 15px;

				input {
					width: 50%;
				}
			}
		}

		.featured-image-container {
			@include make-sm-column(4);

			.featured-image-placeholder {
				background-color: $kbz-grijs;
				width: 100%;
				height: 200px;
			}

			img {
				max-width: 100%;
			}
		}

		.featured-image-upload {
			@include make-sm-column(8);
		}

		label {
			@include make-sm-column(2);
			text-transform: lowercase;
			font-weight: normal;
		}

		.input {
			@include make-sm-column(10);

			.label {
				font-size: 100%;
				font-weight: normal;
			}

			#activate-slug-edit {
				color: #a4a4a4;
				border-color: #a4a4a4;
				text-transform: lowercase;
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 15px;

				&:hover, &:active, &:focus {
					background-color: transparent;
					background-color: #757575;
					border-color: #757575;
					color: #fff;
					outline: none;
				}
			}

			#form-permalink {
				text-transform: lowercase;
			}

			#form-permalink-field {
				font-weight: $bold-font-weight;
				border: 1px solid transparent;

				&.active {
					border-color: #ccc;
					outline: none;
				}
			}
		}

		.checkbox {
			margin: 10px 0 0 20px;

			label {
				width: auto;
				float: none;
				padding: 0;
			}
		}

		&.no-label {
			.input {
				@include make-sm-column(12);
			}
		}

		.chosen-container {
			font-size: $font-size-base;

			&.chosen-container-active {
				border-color: $kbz-donker-blauw;

				.chosen-choices {
					border-color: $kbz-donker-blauw;
					box-shadow: none;
				}
			}

			.chosen-choices {
				padding: 2px 6px;
				border-color: #ccc;
				background-image: none;

				.search-choice {
					font-size: $font-size-base;
					background-color: $kbz-donker-blauw;
					border: 0 !important;
					padding-left: 10px;
					padding-top: 5px;
					padding-bottom: 5px;
					border-radius: 15px;
					box-shadow: none;
					background-image: none;
					color: #fff;

					.search-choice-close {
						font-size: 14px;
						font-weight: normal;
						background-image: none !important;
						top: 5px;

						&:after {
							content: "x";
							padding: 0px 2px;
							color: #fff;
						}
					}
				}
			}

			.chosen-results {
				li {
					&.highlighted {
						background-color: $kbz-donker-blauw;
						background-image: none;
					}
				}
			}
		}

		.bootstrap-tagsinput {
			border-radius: 0 !important;
			box-shadow: none !important;
			width: 100%;

			&.focus {
				border-color: $kbz-donker-blauw;
			}

			.tag {
				padding: 1px 10px 2px 10px;
				border-radius: 15px;
			}

			.tt-menu {
				background: #fff;
				border: 1px solid #ccc;
				border-radius: $border-radius-small;
				overflow: hidden;

				.tt-dataset {
					.tt-selectable {
						padding: 2px 10px;
						cursor: pointer;

						&:hover, &:active {
							background-color: $kbz-donker-blauw;
							color: #fff;
						}
					}
				}
			}
		}

		.form-checkbox-group {
			@include make-row();
			margin-bottom: 25px;

			.form-group {
				@include make-sm-column(3);
			}

			label {
				display: block;
				float: none;
				width: 100%;
				margin-bottom: 10px;
			}

			.checkbox {
				padding-left: 15px;
				margin-top: 0px;

				label {
					margin-left: 5px;
					display: inline;
				}
			}
		}

		&.publication-access {
			.control-label {
				text-align: left !important;
			}

			.radio {
				padding-left: 40px;
			}

			.publication-access-control {
				padding: 20px 0;
				border-bottom: 1px solid #ddd;
				border-top: 1px solid #ddd;

				&:last-of-type {
					border-top: 0;
					margin-bottom: 20px;
				}

				label, .input {
					display: block;
					float: none;
					width: 100%;
				}
			}
		}
	}
}

.icon {
	margin-top: 2px;
	width: 25px;
	height: 14px;
	background-repeat: no-repeat;
	background-position: center;
	display: inline-block;
	background-size: contain;

	&.icon-trash {
		background-image: url(/img/icons/trash.svg);
	}

	&.icon-lock {
		background-image: url(/img/icons/lock.svg);
	}

	&.icon-arrow-up {
		background-image: url(/img/icons/arrow-up.svg);
	}

	&.icon-arrow-down {
		background-image: url(/img/icons/arrow-down.svg);
	}

	&.icon-eye {
		background-image: url(/img/icons/eye.svg);
	}

	&.icon-eye-crossed {
		background-image: url(/img/icons/eye-crossed.svg);
		height: 25px;
	}

	&.icon-edit {
		background-image: url(/img/icons/edit.svg);
		height: 25px;
	}

	&.icon-dossier {
		background-image: url(/img/icons/dossier.svg);
	}

	&.icon-collection {
		background-image: url(/img/icons/collection.svg);
	}
}

#register-form {
	fieldset {
		padding-left: 15px;
		padding-right: 15px;
	}
}

#publication-part-add {
	width: 25px;
	height: 25px;
	display: block;
	background-color: #ccc;
	border: 0;
	font-size: 25px;
	line-height: 20px;
	padding: 0;
	color: #fff;
	border-radius: 50%;
	margin: 0 auto;

	&:hover, &:active, &:focus {
		background-color: $kbz-donker-blauw;
		outline: none;
	}
}

.publication-part-form {
	margin-bottom: 25px;

	.publication-part-word-count {
		text-align: right;
		display: block;
	}
}

.publication-parts-footer {
	border-top: 1px solid $kbz-grijs;
	border-bottom: 1px solid $kbz-grijs;
	padding: 10px 0;
}

#publication-parts-container {
	margin-bottom: 25px;
	padding: 0;


	#publication-parts {
		list-style-type: none;
		padding: 0;
		margin: 0;
		border-top: 1px solid $kbz-grijs;

		li {
			padding: 10px 15px;
			cursor: grab;
			border-bottom: 1px solid $kbz-grijs;
			display: block;
			position: relative;

			.publication-part-header {
				@include make-row();

				.publication-part-title {
					@include make-sm-column-offset(2);
					@include make-sm-column(8);

					h3 {
						font-size: 16px;
						margin-top: 0;
					}
				}

				.publication-part-actions {
					@include make-sm-column(2);
					text-align: right;
				}
			}

			&:hover, &:active {
				background-color: $kbz-grijs;
			}

			&.sortable-chosen {
				cursor: grabbing;
			  background-color: #fff;
			}

			&.sortable-ghost {
				opacity: 0.5;
				background-color: $kbz-donker-blauw;
			}

			.publication-part-toggle, .publication-part-trash {
				border: 0;
				background-color: transparent;

				&:hover, &:active, &:focus {
					background-color: transparent;
					outline: none;
				}
			}

			.view-current-publication-part {
				margin-bottom: 5px;

				a {
					margin-right: 5px;
					color: $kbz-donker-blauw;
				}
			}

			.edit-current-publication-part {
				display: none;
			}

			.btn-close {
				position: absolute;
				top: 12px;
				right: 10px;
				background-color: #ccc;
				border-color: #ccc;
				display: block;
				padding: 0;
				width: 15px;
				height: 15px;
				font-size: 14px;
				line-height: 11px;
				color: #fff;
			}
		}
	}
}

#publication-table {
	thead {
		th {
			padding-top: 0;
			padding-bottom: 50px;
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: 1px;
			border-bottom: 0;

			.publication-table-icon {
				width: 18px;
				height: 18px;
				display: inline-block;
				background-image: url(/img/icons/comment.svg);
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 1;
			}
		}
	}

	tbody {
		tr {
			td {
				position: relative;

				&.title {
					width: 20%;
				}

				&.comment {
					width: 70%;
				}

				&.actions {
					width: 10%;
					text-align: right;
				}

				.publication-comment-excerpt {
					white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

					&.hidden {
						display: block !important;
						visibility: hidden !important;
					}
				}

				.publication-comment {
					border: 10px solid rgba(205, 205, 205, 0.5);
					top: -10px;
					left: -10px;
					position: absolute;
					width: 100%;
					z-index: 5;

					.publication-comment-inner {
						position: relative;
					}

					.publication-comment-close {
						position: absolute;
						top: 0;
						right: 0;
						background-color: transparent;
						border: 0;
					}

					textarea {
						border: 0;
						width: 100%;
						height: 100%;
						padding: 5px;

						&:focus {
							outline: none;
						}
					}
				}

				.publication-trash {
					border: 0;
					background-color: transparent;

					&:hover, &:active {
						background-color: transparent;
					}
				}
			}
		}
	}
}

.publication-typeahead {
	position: relative;
	width: 50%;

	.search-icon {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 12px;
		height: 12px;
		display: block;
		background-image: url(/img/icons/search.svg);
		background-repeat: no-repeat;
		background-size: contain;
		z-index: 1;
	}

	.twitter-typeahead {
		width: 100%;

		.tt-menu {
			width: 100%;
			background: #fff;
			border-radius: 0;
			overflow: hidden;
			border: 1px solid $kbz-donker-blauw;
			border-top: 0;

			.tt-dataset {
				.tt-suggestion {
					cursor: pointer;
					padding: 5px 10px;
					background-color: $kbz-grijs;
					border-bottom: 1px solid #ccc;

					&:last-child {
						border-bottom: 0;
					}

					&:hover, &:active {
						color: #fff;
						background-color: $kbz-donker-blauw;
					}
				}
			}
		}
	}
}
